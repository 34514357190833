import { Row, Spin } from "antd";
import { useEffect, useLayoutEffect, useState } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";
import { Routes } from "./constants/routes.constants";
import Admin from "./pages/Admin";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Homeworks from "./pages/Homeworks";
import { getAllAdmins } from "./services/admins.services";
import { useAuth } from "./services/authentication.services";

function App() {
  const { user, signout } = useAuth();
  const [isAdmin, setIsAdmin] = useState(false);
  const [status, setStatus] = useState("idle");

  useLayoutEffect(() => {
    setStatus("loading");
    getAllAdmins()
      .then((admins) => {
        const isAdmin = admins.find((a) => a?.email === user.email);
        setStatus("idle");
        setIsAdmin(isAdmin);
      })
      .catch(() => {
        setStatus("idle");
      });
  }, [user?.email]);

  /* Add students */
  useEffect(() => {
    [
      "geghamyan11@gmail.com",
      "agabielyan@gmail.com ",
      "anushabrahamyan11@gmail.com",
      "armennersesyanar@gmail.com",
      "fljyanamalya@gmail.com",
      "hayk.grigoryan.1a@gmail.com",
      "heghinebalyan@gmail.com ",
      "kristina.sargsyan.1981@gmail.com",
      "mar.mariam97@gmail.com",
      "murad.vardzelyan@gmail.com",
      "r.durunts@gmail.com",
      "safaryansergey90@gmail.com",
      "suzanna.mkrtchyan99@gmail.com",
      "vardan1594@gmail.com",
      "voskanyanp@gmail.com",
      "birinyan86@gmail.com",
      "khach.rafael@gmail.com",
      "ara.avagyan74@gmail.com",
      "geghamyan11@gmail.com",
      "animakaryan505@gmail.com",
      "anna.martirosiann@gmail.com",
      "anukananyan@gmail.com ",
      "araksyeprikyan@gmail.com",
      "azor1992@yandex.ru",
      "dianagalstyan87@gmail.com",
      "floramatsakyan@gmail.com",
      "gevorgyanofelya1617@gmail.com",
      "hayarpivasilyan@gmail.com",
      "irina.oganesova1986@gmail.com",
      "m.h.khachatryan@gmail.com ",
      "martirosyanalik@gmail.com",
      "marymanukyann@gmail.com",
      "michael.nazaryan1986@gmail.com",
      "sukiasyanem@gmail.com",
      "tamarasedrakian@gmail.com",
      "tatev.tumanyan95@gmail.com",
      "tatevik.manoukian@gmail.com",
      "tatevsevoyan@gmail.com",
      "torosyan.mery@gmail.com",
      "vardanyan9624@gmail.com",
      "vickymasrie@gmail.com",
      "akhojayan@gmail.com",
      "anngalstyan85@gmail.com",
      "asatryanella@gmail.com",
      "goralexanian@gmail.com",
      "hasmik.meloyan96@gmail.com",
      "hasmiktergrigoryan@gmail.com",
      "hchobanyan27@gmail.com",
      "hovhannes.hakobyan4@gmail.com ",
      "hovhannisyanqnarik@gmail.com",
      "l.h.mkhitaryan@gmail.com",
      "lilitsahakyan13@gmail.com",
      "lusine_harutyunyan14@alumni.aua.am",
      "naremnatsakanyan88@gmail.com ",
      "qnarikmiskayan@gmail.com",
      "sahakyanliana006@gmail.com",
      "sebo87@yandex.ru",
      "sonahousehovsepian@gmail.com",
      "syuzanamiryan93@gmail.com",
      "tamazyan.hovik@gmail.com",
      "anngalstyan85@gmail.com",
      "arusik.zakharyan@gmail.com",
      "imusheg@gmail.com",
      "jana.lalayan@gmail.com",
      "anaide.var@gmail.com",
      "arakeliandavit@gmail.com",
      "haykuhi.hovhannis@gmail.com",
      "m.annasimonyan@gmail.com",
      "meline.hovhannissyan@gmail.com",
      "minasyan.mari5@gmail.com",
      "silvihambardzumyan1999@gmail.com",
      "aandreasyan41@gmail.com",
      "anahit.m.muradyan@gmail.com",
      "arman.r.petrosyan@gmail.com",
      "arpihayrapetyan1985@gmail.com",
      "asichyan@gmail.com",
      "hakobyan.armin.88@gmail.com",
      "lana.asatryan@gmail.com",
      "ldavoyan@gmail.com",
      "lilit.aghajanyan.1996@gmail.com",
      "mariam.tunyan@gmail.com",
      "mariamhayruni@gmail.com",
      "samvel.hovh@gmail.com",
      "sargsiantatevik@gmail.com",
      "satimovsesyan@gmail.com",
      "shakhoyanlusine@gmail.com",
      "tatevkhachatryan77@yahoo.com ",
      "vardan.8hayrapetyan@gmail.com",
      "amiryanlilart@gmail.com",
      "an.davtian@gmail.com",
      "ani.saghatelyan@gmail.com",
      "anna.khatshatryan@gmail.com",
      "anna.muradyan1@gmail.com",
      "armi.samvelyan@gmail.com",
      "ashotyan.samvel@gmail.com",
      "astghiknalchajyan@gmail.com",
      "gayane.kharatian@gmail.com",
      "hayk1588@gmail.com",
      "hovhannes.hovhannisyan23@gmail.com",
      "hrachmkhitaryan1985@gmail.com",
      "kristina.hyan@gmail.com",
      "levonarx@gmail.com",
      "lilit.e.poghosyan@gmail.com",
      "lilith.dallakyan@gmail.com",
      "lucy.shahnazaryan7@gmail.com",
      "nelivard7@gmail.com",
      "petrosyan.kristine7@gmail.com",
      "petrosyanlenaa@gmail.com",
      "roza.tovmasyan96@gmail.com",
      "sahakyan.anush1@gmail.com",
      "saribekyanlil7@gmail.com ",
      "surenkhachatryan1989@gmail.com",
      "syuz.avet@gmail.com",
      "wlh9.marianna.ohanyan@gmail.com",
      "hovsepyankarapet@gmail.com",
      "angela.gasparyan1996@gmail.com",
      "armensargsyan.y@gmail.com",
      "ghazaryannarine@gmail.com",
      "harutyunyanannav@gmail.com",
      "hovhannes.mkrtchyan.im@gmail.com",
      "itsdianadaniel@gmail.com",
      "jangozyan.vardan@gmail.com",
      "lalazaryan.ina@gmail.com",
      "lilit.hovnikyan18@gmail.com",
      "lilithpetrosyan@gmail.com",
      "luse.avetisyan@gmail.com",
      "lusinpog@gmail.com",
      "marine.makaryan88@gmail.com",
      "nariharutyunyan@gmail.com",
      "nazeli.a.badalyan@gmail.com",
      "papinyangay@gmail.com",
      "parandzem.hayrikyan@gmail.com",
      "samvelharutyunian@gmail.com",
      "shushanmargaryan91@gmail.com",
      "sirush.shahgeldyan@gmail.com",
      "takiryanx@gmail.com",
      "tamaraharutyunyan92@gmail.com",
      "arusik.zakharyan@gmail.com",
      "sargsyan.smbat@gmail.com",
    ]
      .map((n) => n.trim())
      .forEach((stud, idx) => {
        // addStudent({
        //   email: stud,
        //   idx,
        //   course: "pm",
        //   group: "Doing Product Company X",
        // });
      });
  }, []);

  return user === null || status === "loading" ? (
    <div
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Spin size="large" />
    </div>
  ) : (
    <div style={{ margin: 30 }}>
      {user && (
        <nav style={{ marginBottom: 30 }}>
          <Row>
            <Link to={Routes.home()} style={{ marginRight: 10 }}>
              Home
            </Link>
            <Link to={Routes.homeworks()} style={{ marginRight: 10 }}>
              Homeworks
            </Link>

            {isAdmin && <Link to={Routes.admin()}>Admin</Link>}
            <Link
              onClick={signout}
              style={{ marginLeft: "auto" }}
              to={Routes.admin()}
            >
              Log Out
            </Link>
          </Row>
        </nav>
      )}

      <Switch>
        <div style={{ marging: 30 }}>
          <Route exact path={Routes.home()}>
            {user === false ? <Redirect to={Routes.login()} /> : <Home />}
          </Route>
          <Route exact path={Routes.admin()}>
            {user === false ? <Redirect to={Routes.login()} /> : <Admin />}
          </Route>
          <Route exact path={Routes.homeworks()}>
            {user === false ? <Redirect to={Routes.login()} /> : <Homeworks />}
          </Route>
          <Route exact path={Routes.login()}>
            <Login />
          </Route>
        </div>
      </Switch>
    </div>
  );
}

export default App;
