import React, { useEffect, useState } from "react";
import { getAllAdmins } from "../services/admins.services";
import { useAuth } from "../services/authentication.services";
import {
  Spin,
  Typography,
  Form,
  Input,
  Row,
  Col,
  Button,
  Select,
  notification,
  Tabs,
} from "antd";
import { Routes } from "../constants/routes.constants";
import { Link } from "react-router-dom";
import {
  addStudentToGroup,
  createLesson,
  getAllLessons,
} from "../services/lessons.services";

const { Title } = Typography;
const { Option } = Select;
const { TextArea } = Input;

const AddVideoTab = ({
  isAdmin,
  setIsAdmin,
  user,
  formStatus,
  setFormStatus,
  courses,
}) => {
  const [form] = Form.useForm();
  const [course, setCourse] = useState(null);
  const [groups, setGroups] = useState([]);

  const onFinish = (values) => {
    setFormStatus("loading");
    createLesson(values)
      .then(() => {
        setFormStatus("idle");
        form.resetFields();
        notification.open({
          type: "success",
          message: "The lesson have been created!",
        });
      })
      .catch(() => {
        setFormStatus("error");
      });
  };

  useEffect(() => {
    if (course) {
      const activeCourse = courses.find((c) => c.value === course);

      setGroups(
        Object.entries(activeCourse?.groups || []).map(([key, item]) => ({
          value: key,
          label: item.name,
        }))
      );
    }
  }, [course, courses]);

  return (
    <Spin spinning={formStatus === "loading"}>
      <Form form={form} name="lesson" onFinish={onFinish} layout="vertical">
        <Form.Item name="course" label="Course">
          <Select
            onChange={(value) => {
              setCourse(value);
            }}
            placeholder="Course Name"
          >
            {courses.map(({ value, label }) => {
              return (
                <Option key={value} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="group" label="Groups">
          <Select
            onChange={(value) => {
              setCourse(value);
            }}
            placeholder="Group Name"
          >
            {groups.map(({ value, label }) => {
              return (
                <Option key={value} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
          name="title"
          label="Video Title"
        >
          <Input placeholder="Event Loop" />
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
          name="embed"
          label="Embedded Code"
        >
          <TextArea placeholder="Code from service" rows={8} />
        </Form.Item>

        <Button htmlType="submit" type="primary" size="large">
          Add a video
        </Button>
      </Form>
    </Spin>
  );
};

const AddStudentTab = ({ formStatus, setFormStatus, courses }) => {
  const [form] = Form.useForm();
  const [course, setCourse] = useState(null);
  const [groups, setGroups] = useState([]);

  const onFinish = async (values) => {
    const students = values.students.split("\n").map((s) => s.trim());
    setFormStatus("loading");
    try {
      await Promise.all(
        students.map(async (student) => {
          return await addStudentToGroup(
            { course: values.course, group: values.group },
            {
              email: student,
              id: student.split("@")[0] + Math.round(Math.random() * 10),
            }
          );
        })
      );
      setFormStatus("idle");
      form.resetFields();
      notification.open({
        type: "success",
        message: "The students have been added!",
      });
    } catch (e) {
      notification.open({
        type: "error",
        message: e.message,
      });
    }
  };

  useEffect(() => {
    if (course) {
      const activeCourse = courses.find((c) => c.value === course);

      setGroups(
        Object.entries(activeCourse?.groups || []).map(([key, item]) => ({
          value: key,
          label: item.name,
        }))
      );
    }
  }, [course, courses]);

  return (
    <Spin spinning={formStatus === "loading"}>
      <Form form={form} name="lesson" onFinish={onFinish} layout="vertical">
        <Form.Item name="course" label="Course">
          <Select
            onChange={(value) => {
              setCourse(value);
            }}
            placeholder="Course Name"
          >
            {courses.map(({ value, label }) => {
              return (
                <Option key={value} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item name="group" label="Groups">
          <Select
            onChange={(value) => {
              setCourse(value);
            }}
            placeholder="Group Name"
          >
            {groups.map(({ value, label }) => {
              return (
                <Option key={value} value={value}>
                  {label}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item
          rules={[
            {
              required: true,
              message: "Required",
            },
          ]}
          name="students"
          label="Students"
        >
          <TextArea placeholder="List from excel" rows={8} />
        </Form.Item>

        <Button htmlType="submit" type="primary" size="large">
          Add Students
        </Button>
      </Form>
    </Spin>
  );
};

export default function Admin() {
  const [isAdmin, setIsAdmin] = useState(null);
  const { user } = useAuth();
  const [videoFormStatus, setVideoFormStatus] = useState("idle");
  const [studentFormStatus, setStudentFormStatus] = useState("idle");
  const [courses, setCourses] = useState([]);

  useEffect(() => {
    getAllAdmins().then((admins) => {
      const isAdmin = admins.find((a) => a?.email === user.email);

      if (isAdmin) {
        getAllLessons().then((r) => {
          const courses = Object.entries(r).map(([key, item]) => ({
            value: key,
            label: item.name,
            groups: item.groups,
          }));

          setCourses(courses);
          setIsAdmin(true);
        });
      } else {
        setIsAdmin(false);
        setVideoFormStatus("error");
      }
    });
  }, [user.email]);

  return (
    <Spin spinning={isAdmin === null}>
      {isAdmin ? (
        <Row>
          <Col span={16}>
            <Title>Yay! You're an admin!</Title>
            <Tabs defaultActiveKey="1" centered>
              <Tabs.TabPane tab="Add video" key="1">
                <Title level={3}>Add a recording</Title>
                <Row>
                  <Col span={24} lg={14}>
                    <AddVideoTab
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      user={user}
                      formStatus={videoFormStatus}
                      setFormStatus={setVideoFormStatus}
                      courses={courses}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
              <Tabs.TabPane tab="Add Student to the Group" key="2">
                <Title level={3}>Add a Student to Group</Title>
                <Row>
                  <Col span={24} lg={14}>
                    <AddStudentTab
                      isAdmin={isAdmin}
                      setIsAdmin={setIsAdmin}
                      user={user}
                      formStatus={studentFormStatus}
                      setFormStatus={setStudentFormStatus}
                      courses={courses}
                    />
                  </Col>
                </Row>
              </Tabs.TabPane>
            </Tabs>
          </Col>
        </Row>
      ) : (
        videoFormStatus === "error" && (
          <>
            <Title>You are not an admin!</Title>
            <Link to={Routes.home()}>Return to Home Page</Link>
          </>
        )
      )}
    </Spin>
  );
}
