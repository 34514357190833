import firebase, { database } from "./firebase.services";

export function createUser({ uid, email, emailVerified, displayName }) {
  return firebase
    .database()
    .ref("users/" + uid)
    .set({
      uid,
      email,
      emailVerified,
      displayName,
    })
    .catch((error) => {});
}

export function getUsersById(id) {
  return database
    .ref("/users/" + id)
    .once("value")
    .then((snapshot) => snapshot.val());
}
