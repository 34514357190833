import {
  Button,
  Card,
  Col,
  Collapse,
  Input,
  notification,
  Row,
  Typography,
} from "antd";
import React, { useState } from "react";
import { useAuth } from "../services/authentication.services";

const homeworks = [];

function Homework({ results }) {
  const { user } = useAuth();
  const [name, setName] = useState("");
  const [password, setPassword] = useState("");
  const [userResult, setUserResult] = useState(
    results.find((u) => u["Student Name"] === user.displayName)
  );

  const handleSubmit = () => {
    if (password.trim() === user.uid) {
      const newUser = results.find((u) => u["Student Name"] === name.trim());
      if (!newUser) {
        notification.open({
          type: "error",
          message: "Sorry, something went wrong!",
          description: "Please, contact ACA Team!",
        });
      }
      setUserResult(newUser);
    } else {
      notification.open({
        type: "error",
        message: "Sorry, something went wrong!",
        description: "Please, contact ACA Team!",
      });
    }
  };

  if (!userResult) {
    return (
      <div>
        <h2>Holy guacamole! There's no result 🙄</h2>
        <p style={{ marginBottom: 2 }}>
          Maybe your name in gmail account is incorrect.
        </p>
        <p>
          Please, write the name that you've mentioned submitting the form, so
          we will try to find your results!
        </p>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            maxWidth: 320,
            marginTop: 40,
          }}
        >
          <Input
            onChange={(e) => {
              setName(e.target.value);
            }}
            placeholder="Name (e.g. Vrezh Oganisyan)"
            style={{ maxWidth: 320, marginRight: 10, marginBottom: 15 }}
          />
          <Input
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            placeholder="Password (find in homepage)"
            style={{ maxWidth: 320, marginRight: 10, marginBottom: 25 }}
          />
          <Button onClick={handleSubmit} type="primary">
            Find Result!
          </Button>
        </div>
      </div>
    );
  }

  return (
    <Card
      title={`Grade: ${userResult["Grade (/100)"]} / 100`}
      bordered={false}
      style={{ maxWidth: 640 }}
    >
      <h2>Comment</h2>
      {(userResult["Feedback And Comments"] || "")
        .split("\n")
        .map((content) => (
          <p>{content}</p>
        ))}
    </Card>
  );
}

export default function Homeworks() {
  return (
    <Row justify="center">
      <Col span={24} lg={12}>
        <Typography.Title>Homeworks</Typography.Title>
        <Typography.Paragraph>
          Please check your Google Classroom Account!
        </Typography.Paragraph>
        {homeworks.map((results, idx) => (
          <Collapse collapsible={true} style={{ marginBottom: 15 }}>
            <Collapse.Panel header={`Homework #${idx + 1}`} key={idx}>
              <Homework results={results} />
            </Collapse.Panel>
          </Collapse>
        ))}
      </Col>
    </Row>
  );
}
